import * as React from 'react';
import { SignIn } from './sign_in';
import * as firebaseui from 'firebaseui';
import { AuthenticatedApp } from './authenticated_app';


interface IProps {
    firebaseApp: firebase.app.App;
    firebaseUI: firebaseui.auth.AuthUI;
}

enum AuthState {
    INTIALIZING = 'INITIALIZING',
    SIGNED_OUT = 'SIGNED_OUT',
    SIGNED_IN = 'SIGNED_IN',
}

export const App = (props: IProps) => {
    const {
        firebaseApp,
        firebaseUI,
    } = props;

    const [authState, setAuthState] = React.useState<AuthState>(AuthState.INTIALIZING);

    React.useEffect(() => {
        firebaseApp.auth().onAuthStateChanged((user) => {
            console.log('user changed', user);
            setAuthState(user != null ? AuthState.SIGNED_IN : AuthState.SIGNED_OUT);
        });
    }, []);

    switch(authState) {
        case AuthState.INTIALIZING:
            return null;
        case AuthState.SIGNED_IN:
            return <AuthenticatedApp 
                user={firebaseApp.auth().currentUser} 
                firebaseApp={firebaseApp}
            />;
        case AuthState.SIGNED_OUT:
            return <SignIn
                firebaseUI={firebaseUI}
            />;
    }
};