import * as React from 'react';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import styled from 'styled-components';
import BreadlyLogo from './images/breadly_logo.svg';

interface IProps {
    firebaseUI: firebaseui.auth.AuthUI;
}

var uiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    tosUrl: '/terms-of-services',
    privacyPolicyUrl: '/privacy-policy'
  };


export const SignIn = (props: IProps) => {
    const {
        firebaseUI,
    } = props;

    React.useEffect(() => {
        firebaseUI.start('#firebase-ui-container', uiConfig);
    }, []);

    return (
        <FullPageDialog>
            <img src={BreadlyLogo} />
            <h3>Start baking with Breadly</h3>
            <div>{firebaseUI.isPendingRedirect() ? 'Signing you in ...' : null}</div>
            <div id="firebase-ui-container">
            </div>
        </FullPageDialog>
        );
};

const FullPageDialog = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        max-width: 150px;
    }
`;