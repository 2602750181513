import * as React from 'react';
import styled from 'styled-components';
import BreadlyLogo from './images/breadly_logo.svg';
import { RecipeList } from './recipe_list';


interface IProps {
    user: firebase.User
    firebaseApp: firebase.app.App;
}

export const AuthenticatedApp = (props: IProps) => {
    const {
        user,
        firebaseApp,
    } = props;

    return (
        <AppScreen>
            <Header>
                <img src={BreadlyLogo} />
                breadly
                <Spacer />
                <SignOutButton onClick={() => firebaseApp.auth().signOut()}>
                    Sign Out
                </SignOutButton>
            </Header>
            <Page>
                <RecipeList />
            </Page>
        </AppScreen>
    );
};

const AppScreen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const Spacer = styled.div`
    flex: 1;
`;

const Header = styled.div`
    min-height: 5vh;
    background-color: brown;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.15em 1em;

    img {
        max-height: 25px;
        margin: 5px;
    }
`;

const SignOutButton = styled.button`
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border-radius: 5px;

    :hover {
        background-color: rgba(255, 255, 255, 0.2)
    }
`;

const Page = styled.div`
    flex: 1;
    padding: 20px;
`;