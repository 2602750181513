import * as React from 'react';

interface IProps {
    
}

export const RecipeList = (props: IProps) => {
    return <div>
        <div>
            No Recipes to display.
        </div>
        <div>Create new recipe</div>
    </div>;
};