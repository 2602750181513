import * as React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';

import { App } from './app';

const firebaseConfig = {
    apiKey: "AIzaSyA4ETahsPGPENSc2IG42b7LxtnVQbHENeo",
    authDomain: "breadly-42b28.firebaseapp.com",
    databaseURL: "https://breadly-42b28.firebaseio.com",
    projectId: "breadly-42b28",
    storageBucket: "breadly-42b28.appspot.com",
    messagingSenderId: "237333783023",
    appId: "1:237333783023:web:95fba7075c3188cff3d61a"
  };


const app = firebase.initializeApp(firebaseConfig);
const ui = new firebaseui.auth.AuthUI(app.auth());
ReactDOM.render(
    <App 
        firebaseApp={app}
        firebaseUI={ui}
    />, document.getElementById('appContainer'));
